<template>
  <PageWithLayout>
    <Tab
      :tabData="viewModel.tabData"
      :value.sync="viewModel.tabData.value"
      @update:value="value => viewModel.onUpdateTab(value)"/>
    <Board
      :boardData="viewModel.deployData[viewModel.tabData.value].REGIVATION.boardData"
      :searchParams.sync="viewModel.deployData[viewModel.tabData.value].REGIVATION.searchParams"
      :dataList="viewModel.deployData[viewModel.tabData.value].REGIVATION.dataList"
      :paginationData="viewModel.deployData[viewModel.tabData.value].REGIVATION.paginationData"
      @onClickRow="(rowId, rowData) => viewModel.onClickRow(rowId, rowData)">
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="약관 배포 추가"
          @onClickBtn="viewModel.onClickAdd()">
          <IconSvg iconName="add" :size="12" iconColor="#fff" />
        </Button>
      </template>
    </Board>
    <Board
      :boardData="viewModel.deployData[viewModel.tabData.value].DEPLOIED.boardData"
      :searchParams.sync="viewModel.deployData[viewModel.tabData.value].DEPLOIED.searchParams"
      :dataList="viewModel.deployData[viewModel.tabData.value].DEPLOIED.dataList"
      :paginationData="viewModel.deployData[viewModel.tabData.value].DEPLOIED.paginationData"
      @onClickRow="(rowId, rowData) => viewModel.onClickRow(rowId, rowData)">
    </Board>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Tab from '@lemontree-ai/lemontree-admin-common-front/components/common/tab/Tab';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
// viewModel
import TermListViewModel from '@/views/service/term/viewModel/TermListViewModel'

export default {
  name:'TermList',
  components:{
    PageWithLayout,
    Tab,
    Board,
    Button,
    IconSvg,
  },
  beforeMount(){
    const type = this.$route.query.type;
    this.viewModel.init(type);
  },
  data(){
    return{
      viewModel: new TermListViewModel(),
    }
  },
}
</script>
import router from '@/router';

export default class TermListViewModel {
  constructor() {
    this.tabData = {
      dataList: [
        { id: 'SERVICE', text:'페이 이용약관' },
        // { id: 'PRIVITE', text:'개인정보 취급방침' },
      ],
      option:{
        isCount: false,
        queryKeyName:'type'
      },
      value: 'SERVICE'
    }
    this.deployData = {
      SERVICE:{
        REGIVATION:{
          dataList: [],
          boardData : {
            title:'배포 예정 목록',
            drawDataList: [
              {
                title:'No.',
                width:'60px',
                value:'id',
                isSorting: true
              },
              // {
              //   title:'약관',
              //   width:'',
              //   value:'title',
              //   isAlignLeft: true
              // },
              {
                title:'배포예정일',
                width:'',
                value:'deploy_at',
                filter:{
                  name:'convertDateFormat',
                  value:'YYYY-MM-DD'
                },
                emptyValueText:'미정'
              },
              {
                title:'배포승인',
                width:'80px',
                value:'deploior',
                emptyValueText:'-'
              },
              {
                title:'상태',
                width:'120px',
                value:'status',
                filter: { name:'convertIdToText', value:'term_status' },
                badge: { badgeColorFilter:{ name:'convertIdToColor', dataListName:'term_status' }, badgeFill: false }
              },
            ],
            option:{
              isTotal: true,
              isSize: true
            }
          },
          searchParams: {
            page:0,
            pageSize:10,
            direction: 'ASC'
          },
          paginationData: {
            totalCount:0,
            totalPage:0
          }
        },
        DEPLOIED:{
          dataList: [],
          boardData : {
            title:'배포 목록',
            drawDataList: [
              {
                title:'No.',
                width:'60px',
                value:'id',
                isSorting: true
              },
              // {
              //   title:'약관',
              //   width:'',
              //   value:'title',
              //   isAlignLeft: true
              // },
              {
                title:'배포일',
                width:'',
                value:'deploy_at',
                filter:{
                  name:'convertDateFormat',
                  value:'YYYY-MM-DD'
                },
              },
              {
                title:'배포승인',
                width:'80px',
                value:'deploior',
              },
              {
                title:'게시링크',
                width:'120px',
                value:'',
                button: { text:'페이지 열기', size:'small', style:'secondary_border', onClickEvent: (data) => this.onClickShowTermPage(data) },
              },
            ],
            option:{
              isTotal: true,
              isSize: true
            }
          },
          searchParams: {
            page:0,
            pageSize:10,
            direction: 'ASC'
          },
          paginationData: {
            totalCount:0,
            totalPage:0
          }
        }
      },
      PRIVITE:{
        REGIVATION:{
          dataList: [],
          boardData : {
            title:'배포 예정 목록',
            drawDataList: [
              {
                title:'No.',
                width:'60px',
                value:'id',
                isSorting: true
              },
              // {
              //   title:'약관',
              //   width:'',
              //   value:'title',
              //   isAlignLeft: true
              // },
              {
                title:'배포예정일',
                width:'',
                value:'deploy_at',
                filter:{
                  name:'convertDateFormat',
                  value:'YYYY-MM-DD'
                },
                emptyValueText:'미정'
              },
              {
                title:'배포승인',
                width:'80px',
                value:'deploior',
                emptyValueText:'-'
              },
              {
                title:'상태',
                width:'120px',
                value:'status',
                filter: { name:'convertIdToText', value:'term_status' },
                badge: { badgeColorFilter:{ name:'convertIdToColor', dataListName:'term_status' }, badgeFill: false }
              },
            ],
            option:{
              isTotal: true,
              isSize: true
            }
          },
          searchParams: {
            page:0,
            pageSize:10,
            direction: 'ASC'
          },
          paginationData: {
            totalCount:0,
            totalPage:0
          }
        },
        DEPLOIED:{
          dataList: [],
          boardData : {
            title:'배포 목록',
            drawDataList: [
              {
                title:'No.',
                width:'60px',
                value:'id',
                isSorting: true
              },
              // {
              //   title:'약관',
              //   width:'',
              //   value:'title',
              //   isAlignLeft: true
              // },
              {
                title:'배포일',
                width:'',
                value:'deploy_at',
                filter:{
                  name:'convertDateFormat',
                  value:'YYYY-MM-DD'
                },
              },
              {
                title:'배포승인',
                width:'80px',
                value:'deploior',
              },
              {
                title:'게시링크',
                width:'120px',
                value:'',
                button: { text:'페이지 열기', size:'small', style:'secondary_border', onClickEvent: (data) => this.onClickShowTermPage(data) },
              },
            ],
            option:{
              isTotal: true,
              isSize: true
            }
          },
          searchParams: {
            page:0,
            pageSize:10,
            direction: 'ASC'
          },
          paginationData: {
            totalCount:0,
            totalPage:0
          }
        }
      }
    };
    this.checkboxOnlyOne = false;
    this.selectedDataList = [];
  }
  init(type){
    if(type){
      this.tabData.value = type;
      this.onUpdateTab(type)
    }
    this.getData();
  }
  onUpdateTab(value){
    this.getData();
  }
  onClickAdd(){
    router.push({ name: 'SERVICE_TERM_REGISTER', params:{ type:this.tabData.value } });
  }
  onClickShowTermPage(data){
    window.open('https://service.dev.lemontree.ai/term/', '_blank'); 
  }
  onClickRow(rowId, rowData){
    if(rowData.status === 'TEMP'){
      router.push({ name: 'SERVICE_TERM_MODIFY', params: { type:this.tabData.value, id : rowId } });
    }else{
      router.push({ name: 'SERVICE_TERM_DETAIL', params: { type:this.tabData.value, id : rowId } });
    }
  }
  getData(){
    const resultDataList = {
      SERVICE:{
        REGIVATION:[
          {
            id:'5',
            content:'<h1>제1장 총칙</h1><h2>제1조 (목적)</h2><p>본 약관은 주식회사 레몬트리(이하 \"회사\"라 함)가 제공하는 전자금융거래 서비스 및 관련 서비스(이하 \"서비스\"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.</p><p></p><h2>제2조 (용어의 정의)</h2><p>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p><p>1. \"회원\"이란 본 약관에 따라 가입신청에 대한 회사의 승낙을 받아 회사가 제공하는 서비스를 이용하는 자를 말합니다.</p><p>2. \"퍼핀앱\"이란 컴퓨터 등의 정보통신설비 또는 스마트폰, 태블릿 등의 모바일 정보통신설비를 이용하여 회사가 서비스를 제공할 수 있도록 설정한 가상의 영업장 또는 서비스 공간을 말하며, 애플리케이션, 웹페이지 등을 포함합니다.</p><p>3. \"접근매체\"라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 전자금융거래법 제2조 제10호에서 정하는 수단 또는 정보로서, 전자금융거래서비스를 이용하기 위하여 회사에 등록된 암호 또는 전자금융업자에 등록된 회원번호 등 회사가 지정한 수단을 말합니다.</p>',
            isEssential:false,
            deploy_at:'2022-08-25T00:00:00',
            deploior:'유보현',
            status:'REGIVATION'
          },
          {
            id:'4',
            content:'<h1>제1장 총칙</h1><h2>제1조 (목적)</h2><p>본 약관은 주식회사 퍼핀(이하 \"회사\"라 함)가 제공하는 전자금융거래 서비스 및 관련 서비스(이하 \"서비스\"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.</p><p></p><h2>제2조 (용어의 정의)</h2><p>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p><p>1. \"회원\"이란 본 약관에 따라 가입신청에 대한 회사의 승낙을 받아 회사가 제공하는 서비스를 이용하는 자를 말합니다.</p><p>2. \"퍼핀앱\"이란 컴퓨터 등의 정보통신설비 또는 스마트폰, 태블릿 등의 모바일 정보통신설비를 이용하여 회사가 서비스를 제공할 수 있도록 설정한 가상의 영업장 또는 서비스 공간을 말하며, 애플리케이션, 웹페이지 등을 포함합니다.</p><p>3. \"접근매체\"라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 전자금융거래법 제2조 제10호에서 정하는 수단 또는 정보로서, 전자금융거래서비스를 이용하기 위하여 회사에 등록된 암호 또는 전자금융업자에 등록된 회원번호 등 회사가 지정한 수단을 말합니다.</p>',
            isEssential:false,
            deploy_at:'',
            deploior:'',
            status:'TEMP'
          },
        ],
        DEPLOIED:[
          {
            id:'3',
            content:'<h1>제1장 총칙</h1><h2>제1조 (목적)</h2><p>본 약관은 주식회사 레몬트리(이하 \"회사\"라 함)가 제공하는 전자금융거래 서비스 및 관련 서비스(이하 \"서비스\"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.</p><p></p><h2>제2조 (용어의 정의)</h2><p>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p><p>1. \"회원\"이란 본 약관에 따라 가입신청에 대한 회사의 승낙을 받아 회사가 제공하는 서비스를 이용하는 자를 말합니다.</p><p>2. \"레몬트리앱\"이란 컴퓨터 등의 정보통신설비 또는 스마트폰, 태블릿 등의 모바일 정보통신설비를 이용하여 회사가 서비스를 제공할 수 있도록 설정한 가상의 영업장 또는 서비스 공간을 말하며, 애플리케이션, 웹페이지 등을 포함합니다.</p><p>3. \"접근매체\"라 함은 전자금융거래에 있어서 거래지시를 하거나 이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여 사용되는 전자금융거래법 제2조 제10호에서 정하는 수단 또는 정보로서, 전자금융거래서비스를 이용하기 위하여 회사에 등록된 암호 또는 전자금융업자에 등록된 회원번호 등 회사가 지정한 수단을 말합니다.</p>',
            isEssential:false,
            deploy_at:'2022-06-30T00:00:00',
            deploior:'유보현',
            status:'DEPLOIED'
          },
          {
            id:'2',
            content:'<h1>제1장 총칙</h1><h2>제1조 (목적)</h2><p>본 약관은 주식회사 레몬트리(이하 \"회사\"라 함)가 제공하는 전자금융거래 서비스 및 관련 서비스(이하 \"서비스\"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.</p><p></p><h2>제2조 (용어의 정의)</h2><p>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p><p>1. \"회원\"이란 본 약관에 따라 가입신청에 대한 회사의 승낙을 받아 회사가 제공하는 서비스를 이용하는 자를 말합니다.</p><p>2. \"레몬트리앱\"이란 컴퓨터 등의 정보통신설비 또는 스마트폰, 태블릿 등의 모바일 정보통신설비를 이용하여 회사가 서비스를 제공할 수 있도록 설정한 가상의 영업장 또는 서비스 공간을 말하며, 애플리케이션, 웹페이지 등을 포함합니다.</p>',
            isEssential:false,
            deploy_at:'2022-06-13T00:00:00',
            deploior:'유보현',
            status:'DEPLOIED'
          },
          {
            id:'1',
            content:'<h1>제1장 총칙</h1><h2>제1조 (목적)</h2><p>본 약관은 주식회사 레몬트리(이하 \"회사\"라 함)가 제공하는 전자금융거래 서비스 및 관련 서비스(이하 \"서비스\"라 함)의 이용과 관련하여, 회사와 회원 간 또는 회원 간의 권리 및 의무 및 책임사항 및 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.</p>',
            isEssential:false,
            deploy_at:'2022-06-02T00:00:00',
            deploior:'유보현',
            status:'DEPLOIED'
          },
        ]
      },
      PRIVITE:{
        REGIVATION:[
          {
            id:'5',
            content:'<h1>제1조 (처리 목적과 항목)</h1><p>1. 회사는 서비스 이용에 반드시 필요한 개인신용정보를 다음과 같은 목적을 위하여 처리합니다. 처리하는 개인신용정보는 다음의 목적 이외의 용도로는 처리되지 않으며, 처리 목적과 항목이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할 것입니다.</p><p>2. 회사는 레몬트리 서비스 (이하 “서비스”) 이용에 필요한 최소한의 개인신용정보만을 수집하고 있습니다.</p><p>3. 처음으로 이용하는 서비스의 경우 회사는 이용자에게 필수적인 정보의 입력을 요청할 수 있으며, 회사가 이미 보유한 정보가 있다면 입력을 생략하거나 이용자를 위하여 입력편의성을 제공할 수 있습니다.</p>',
            isEssential:false,
            deploy_at:'2022-08-25T00:00:00',
            deploior:'유보현',
            status:'REGIVATION'
          },
        ],
        DEPLOIED:[
          {
            id:'1',
            content:'<h1>제1조 (처리 목적과 항목)</h1><p>1. 회사는 서비스 이용에 반드시 필요한 개인신용정보를 다음과 같은 목적을 위하여 처리합니다. 처리하는 개인신용정보는 다음의 목적 이외의 용도로는 처리되지 않으며, 처리 목적과 항목이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할 것입니다. </p>',
            isEssential:false,
            deploy_at:'2022-06-02T00:00:00',
            deploior:'유보현',
            status:'DEPLOIED'
          },
        ]
      },
    };
    this.deployData.SERVICE.REGIVATION.dataList = resultDataList.SERVICE.REGIVATION;
    this.deployData.SERVICE.REGIVATION.paginationData.totalCount = resultDataList.SERVICE.REGIVATION.length;
    this.deployData.SERVICE.REGIVATION.paginationData.totalPage = 1;

    this.deployData.SERVICE.DEPLOIED.dataList = resultDataList.SERVICE.DEPLOIED;
    this.deployData.SERVICE.DEPLOIED.paginationData.totalCount = resultDataList.SERVICE.DEPLOIED.length;
    this.deployData.SERVICE.DEPLOIED.paginationData.totalPage = 1;

    this.deployData.PRIVITE.REGIVATION.dataList = resultDataList.PRIVITE.REGIVATION;
    this.deployData.PRIVITE.REGIVATION.paginationData.totalCount = resultDataList.PRIVITE.REGIVATION.length;
    this.deployData.PRIVITE.REGIVATION.paginationData.totalPage = 1;

    this.deployData.PRIVITE.DEPLOIED.dataList = resultDataList.PRIVITE.DEPLOIED;
    this.deployData.PRIVITE.DEPLOIED.paginationData.totalCount = resultDataList.PRIVITE.DEPLOIED.length;
    this.deployData.PRIVITE.DEPLOIED.paginationData.totalPage = 1;
  }
}